import { PageProps } from "gatsby";
import React from "react";

import { AboutSheetMusic } from "../components/pages/AboutSheetMusic";

import { snProps } from "../js/utils";

const AboutSheetMusicPage = (props: PageProps) => (
  <AboutSheetMusic {...{ ...snProps, ...props }} />
);

export default AboutSheetMusicPage;
